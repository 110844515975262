import { styled } from 'baseui'

export const AccordionOverrides = {
  ToggleIcon: {
    style: ({ $theme }) => ({
      color: $theme.colors.warning
    })
  },
  Header: {
    style: ({ $theme }) => ({
      backgroundColor: $theme.colors.warning100
    })
  },
  Content: {
    style: ({ $theme }) => ({
      backgroundColor: $theme.colors.warning100,
      paddingLeft: $theme.sizing.scale700,
      paddingRight: $theme.sizing.scale700,
      paddingBottom: $theme.sizing.scale600,
      paddingTop: 0
    })
  },
  PanelContainer: {
    style: ({ $theme }) => ({
      borderBottomColor: $theme.colors.warning300,
      borderTopColor: $theme.colors.warning300,
      borderRightColor: $theme.colors.warning300,
      borderLeftColor: $theme.colors.warning300,
      borderBottomStyle: $theme.borders.borderStyle,
      borderTopStyle: $theme.borders.borderStyle,
      borderRightStyle: $theme.borders.borderStyle,
      borderLeftStyle: $theme.borders.borderStyle,
      borderTopWidth: $theme.borders.borderWidth,
      borderRightWidth: $theme.borders.borderWidth,
      borderLeftWidth: $theme.borders.borderWidth,
      borderTopLeftRadius: $theme.borders.radius200,
      borderTopRightRadius: $theme.borders.radius200,
      borderBottomLeftRadius: $theme.borders.radius200,
      borderBottomRightRadius: $theme.borders.radius200,
      overflow: 'hidden',
      marginBottom: '16px'
    })
  }
}

export const TypographyStyle = {
  overflowWrap: 'break-word',
  wordBreak: 'break-word'
}

export const AlertLabel = styled('div', {
  lineHeight: '25px'
})
