import { CheckCircle, WarningOctagon } from '@phosphor-icons/react'
import React, { useMemo } from 'react'
import { Block } from 'baseui/block'
import { useStyletron } from 'baseui'
import { LabelSmall } from 'baseui/typography'
import {
  getDriverEtaLabel,
  isDriverEtaThresholdExceeded
} from 'components/utils/is-driver-eta-threshold-exceeded'
import { BlockContainer, BlockIconContainer } from './DriverETA.styled'

const DriverETA = ({ driverETA, arrivalTime }) => {
  const [css, theme] = useStyletron()

  const isEtaThresholdExceeded = useMemo(
    () => isDriverEtaThresholdExceeded(driverETA, arrivalTime),
    [driverETA, arrivalTime]
  )

  const getDaysDiff = useMemo(
    () => getDriverEtaLabel(driverETA, arrivalTime),
    [driverETA, arrivalTime]
  )

  if (!driverETA) {
    return <></>
  }

  return (
    <BlockContainer data-testId="driver-eta-container">
      <BlockIconContainer>
        <LabelSmall>
          {isEtaThresholdExceeded ? (
            <WarningOctagon size={28} color={theme.colors.warning300} />
          ) : (
            <CheckCircle size={28} color={theme.colors.positive} />
          )}
        </LabelSmall>
      </BlockIconContainer>
      <Block>
        <LabelSmall data-testId="driver-eta-time">{getDaysDiff}</LabelSmall>
      </Block>
    </BlockContainer>
  )
}

export default DriverETA
