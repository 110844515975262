import { styled } from 'baseui'
import { Block } from 'baseui/block'

export const BlockContainer = styled(Block, {
  display: 'flex',
  alignItems: 'center'
})

export const BlockIconContainer = styled(Block, {
  marginRight: '6px'
})
