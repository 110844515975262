import {
  CustomLabelsContext,
  INITIAL_CUSTOM_FIELD_NAMES
} from 'components/contexts/custom-labels-context'
import { appointmentService } from 'components/services'
import { useContext, useEffect, useState } from 'react'
import { FacilitiesContext } from '../context/facilities-context'
import moment from 'moment'
import { INBOUND_TYPE, OUTBOUND_TYPE } from 'components/constants/appointment_types'
import {
  APPOINTMENT_DURATION_FALLBACK,
  DEFAULT_APPOINTMENT_DURATIONS
} from 'components/constants/default-appointment-durations'
import { AppointmentStatus } from 'components/models/Milestones'

function useDropAppointments(id: string, shouldReload?: boolean) {
  const [appointment, setAppointment] = useState<any>(null)
  const [loading, setLoading] = useState<any>(false)
  const { setCustomLabels } = useContext(CustomLabelsContext)
  const { facilities } = useContext(FacilitiesContext)

  useEffect(() => {
    setLoading(true)
    loadAppointment()
  }, [id, shouldReload])

  const loadAppointment = async () => {
    appointmentService
      .get(id)
      .then(([data, status]) => {
        if ([304, 200].includes(status)) {
          setAppointment(data)
          setCustomLabels({ ...INITIAL_CUSTOM_FIELD_NAMES, ...data.customLabels })
        }
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  let duration =
    DEFAULT_APPOINTMENT_DURATIONS[appointment?.equipmentTypeName] || APPOINTMENT_DURATION_FALLBACK

  if (
    appointment?.facilityId &&
    facilities[appointment?.facilityId]?.appointmentDurationsHash &&
    facilities[appointment?.facilityId]?.appointmentDurationsHash[appointment?.appointmentTypeId] &&
    facilities[appointment?.facilityId]?.appointmentDurationsHash[appointment?.appointmentTypeId][
      appointment?.equipmentTypeId
    ]
  ) {
    duration =
      facilities[appointment?.facilityId].appointmentDurationsHash[appointment?.appointmentTypeId][
        appointment?.equipmentTypeId
      ].duration
  }
  const startTime = moment(appointment?.arrivalTime)
  const endTime = moment(startTime).add(duration, 'minutes')
  const appointmentType = [appointment?.appointmentType]
  if (appointment?.relatedAppointment && appointment?.relatedAppointment?.appointmentType) {
    appointmentType.push(appointment?.relatedAppointment?.appointmentType)
  }

  if (!appointment) {
    return {
      appointment: null
    }
  }

  let isNewApprovalActive = false
  let isRescheduleApprovalActive = false

  if (appointment?.facilityId && facilities[appointment?.facilityId]) {
    const appointmentType = facilities[appointment?.facilityId]?.appointmentTypes?.filter(
      item =>
        item.id == appointment.appointmentTypeId ||
        item.id == appointment?.relatedAppointment?.appointmentTypeId
    )
    const appointmentTypeFacility =
      appointmentType &&
      appointmentType.length &&
      appointmentType[0].appointmentTypesFacilities &&
      appointmentType[0].appointmentTypesFacilities
        .filter(item => item.facilityId == appointment?.facilityId)
        .map(item => ({
          verifyApprovalNewAppointment: item.verifyApprovalNewAppointment,
          verifyApprovalRescheduleAppointment: item.verifyApprovalRescheduleAppointment
        }))
    if (appointmentTypeFacility?.length) {
      isNewApprovalActive = appointmentTypeFacility.some(item => item.verifyApprovalNewAppointment)
      isRescheduleApprovalActive = appointmentTypeFacility.some(
        item => item.verifyApprovalRescheduleAppointment
      )
    }
  }
  const formatHeaderDate = (start, end) => {
    const dayOfWeek = moment(start).format('dddd')
    const monthDay = moment(start).format('MMM D')

    const formatTime = time => time.format('h:mm a')

    return `${dayOfWeek}, ${monthDay} | ${formatTime(start)} - ${formatTime(end)}`
  }

  const getAppointmentStatus = () => {
    if (
      appointment.status === AppointmentStatus.REQUESTED &&
      (isNewApprovalActive || isRescheduleApprovalActive)
    ) {
      return AppointmentStatus.REQUESTED
    } else if (
      appointment.status === AppointmentStatus.REQUESTED &&
      !isNewApprovalActive &&
      !isRescheduleApprovalActive
    ) {
      return AppointmentStatus.SCHEDULED
    } else {
      return appointment.status
    }
  }

  const getDriverEta = () => {
    if (appointment.driverEta && appointment.status === AppointmentStatus.SCHEDULED) {
      return new Date(appointment.driverEta)
    }
  }

  return {
    appointment: {
      id: appointment.id,
      facilityId: appointment.facilityId,
      createdAt: new Date(appointment.createdAt),
      updatedAt: new Date(appointment.updatedAt),
      arrivalTime: new Date(appointment.arrivalTime),
      dockTime: appointment.dockTime ? new Date(appointment.dockTime) : undefined,
      appointmentTypeId: appointment.appointmentTypeId,
      createdByUserName: appointment.createdByAuditUserName,
      schedulerId: appointment.schedulerId,
      schedulerName: appointment.schedulerName,
      dockType: appointment.dock?.dockType,
      dockName: appointment.dock?.name,
      purchaseOrdersAttributes: appointment.purchaseOrders,
      confirmationId: appointment.confirmationId,
      answersAttributes: appointment.answersAttributes,
      readOnly: appointment.readOnly,
      handlingMethod: appointment.handlingMethod,
      checkinId: appointment.checkinId,
      facilityName: appointment.facilityName,
      carrierId: appointment.carrierId,
      driverName: appointment.driverName,
      driverId: appointment.driverId,
      carrierName: appointment.carrierName,
      equipmentTypeId: appointment.equipmentTypeId,
      equipmentTypeName: appointment.equipmentTypeName,
      headerDate: formatHeaderDate(startTime, endTime),
      startTime: appointment?.arrivalTime,
      endTime: endTime.toISOString(),
      appointmentType: appointmentType.join(' | '),
      isNewApprovalActive: isNewApprovalActive,
      isRescheduleApprovalActive: isRescheduleApprovalActive,
      isRequestedAppointment:
        (isNewApprovalActive || isRescheduleApprovalActive) &&
        appointment.status == AppointmentStatus.REQUESTED,
      dropInfo: {
        equipmentType: appointment?.equipmentTypeName,
        purchaseOrdersAttributes: appointment?.purchaseOrders,
        appointmentType: appointment?.appointmentType,
        trailer: {
          status: appointment?.trailer?.status,
          number: appointment?.trailer?.number,
          state: appointment?.trailer?.state
        }
      },
      ...(appointment?.relatedAppointment && {
        relatedAppointmentDropInfo: {
          equipmentType: appointment?.relatedAppointment?.equipmentTypeName,
          purchaseOrdersAttributes: appointment?.relatedAppointment?.purchaseOrders,
          appointmentType: appointment?.relatedAppointment?.appointmentType,
          trailer: {
            status: appointment?.relatedAppointment?.trailer?.status,
            number: appointment?.relatedAppointment?.trailer?.number,
            state: appointment?.relatedAppointment?.trailer?.state
          }
        }
      }),
      status: getAppointmentStatus(),
      driverETA: getDriverEta()
    },
    appointmentData: appointment,
    loading
  }
}

export default useDropAppointments
