import React from 'react'
import { DRIVER_ETA_MIN_THRESHOLD } from 'components/constants/driver-eta'
import moment from 'moment-timezone'
import { primitives } from 'components/homepage/custom-theme'
import { t } from 'i18next'

export const isDriverEtaThresholdExceeded = (driverETA: Date, arrivalTime: Date): boolean => {
  const totalDiff = moment(driverETA).diff(moment(arrivalTime), 'minutes')
  return Math.abs(totalDiff) > DRIVER_ETA_MIN_THRESHOLD
}

export const getDriverEtaThresholdExceededMinutes = (
  driverETA: Date,
  arrivalTime: Date
): number => {
  const totalDiff = moment(driverETA).diff(moment(arrivalTime), 'minutes')
  return totalDiff
}

export const isDriverEtaThresholdExceededDays = (driverETA: Date, arrivalTime: Date): number => {
  const totalDaysDiff = moment(driverETA)
    .startOf('day')
    .diff(moment(arrivalTime).startOf('day'), 'days')
  return totalDaysDiff
}

export const getDriverEtaDaysDiff = (driverETA: Date, arrivalTime: Date) => {
  const daysDiff = isDriverEtaThresholdExceededDays(driverETA, arrivalTime)
  return daysDiff !== 0 ? <sup>{`${daysDiff > 0 ? `+${daysDiff}` : daysDiff}`}</sup> : ''
}

export const getDriverEtaLabel = (driverETA: Date, arrivalTime: Date) => {
  return (
    <>
      {`ETA: ${moment(driverETA).format('h:mm a')}`} {getDriverEtaDaysDiff(driverETA, arrivalTime)}
    </>
  )
}

export const getDriverEtaTime = (driverETA: Date, arrivalTime: Date) => {
  return (
    <>
      {moment(driverETA).format('h:mm')}
      {getDriverEtaDaysDiff(driverETA, arrivalTime)}
    </>
  )
}

export const getDriverEtaTileColor = (color: string, isWarning = false) => {
  let iconColor = primitives.positive
  if (isWarning) {
    iconColor = color == primitives.warningSecondary ? primitives.warning : primitives.warning300
  } else {
    iconColor = color == primitives.positive ? primitives.accent : primitives.positive
  }
  return iconColor
}

export const getDriverEtaLabelAlert = (driverETA: Date, arrivalTime: Date) => {
  const isLater = getDriverEtaThresholdExceededMinutes(driverETA, arrivalTime) > 0
  return (
    <>
      {`ETA: ${moment(driverETA).format('h:mm a')}`} {getDriverEtaDaysDiff(driverETA, arrivalTime)}
      {isLater
        ? `(${t('Appointments.DriverEta.Alert.Late.Text')})`
        : `(${t('Appointments.DriverEta.Alert.Early.Text')})`}
    </>
  )
}
