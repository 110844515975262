import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid'
import { LabelXSmall } from 'baseui/typography'
import { Appointment } from 'components/appointments/types'
import authenticatedFetch from 'components/utils/authenticated-fetch'
import AuditLogEntry from './audit-log-entry'
import { User } from '../../../../../models/User'
import { useStyletron } from 'baseui'
import EtaLogEntry from './eta-log-entry'

interface AuditLogProps {
  appointment: Appointment
  isShowDropAppointment?: boolean
  isShowEtaLogs?: boolean
}

export const CREATE = 'create'
export const UPDATE = 'update'
export const DESTROY = 'destroy'
export type AUDITED_ACTION = typeof CREATE | typeof UPDATE | typeof DESTROY
export const ETA_LOG_TYPE = 'driver_eta'

export interface AuditLog {
  id: string
  auditedChanges: any
  action: AUDITED_ACTION
  userId: string
  username: string
  createdAt: Date
  comment: string
  auditableType: 'Appointment' | 'Answer' | 'PurchaseOrder'
  user?: User
  arrivalTime?: Date
}

const AuditLogs: FC<AuditLogProps> = props => {
  const { appointment, isShowDropAppointment, isShowEtaLogs = false } = props
  const [css] = useStyletron()
  const { t } = useTranslation()

  const [auditLogs, setAuditLogs] = useState<AuditLog[]>([])

  useEffect(() => {
    if (appointment.id) {
      authenticatedFetch({
        path: `/appointments/${appointment.id}/audits.json`
      }).then(([json, status]) => {
        if ([304, 200].includes(status)) {
          setAuditLogs(
            json.map(auditLog => ({
              ...auditLog,
              createdAt: new Date(auditLog.createdAt),
              arrivalTime: appointment?.arrivalTime
            }))
          )
        }
      })
    }
  }, [appointment.id])

  const filteredLogs = auditLogs.filter(item => {
    const hasDriverEta = !!item.auditedChanges?.driver_eta
    return isShowEtaLogs ? hasDriverEta : !hasDriverEta
  })

  return (
    <>
      <LabelXSmall marginBottom="scale400">
        {t(
          `Appointments.EditAppointmentModal.${isShowEtaLogs ? 'EtaLogs' : 'AuditLogs'}.Header.Text`
        )}
      </LabelXSmall>
      <FlexGrid
        className={css({
          overflow: 'scroll',
          height: isShowDropAppointment ? 'auto' : 'calc(100vh - 420px)'
        })}>
        {filteredLogs.map((log, index) => {
          return (
            <FlexGridItem key={index}>
              {isShowEtaLogs ? <EtaLogEntry {...log} /> : <AuditLogEntry {...log} />}
            </FlexGridItem>
          )
        })}
      </FlexGrid>
    </>
  )
}

export default AuditLogs
