import { Accordion, Panel } from 'baseui/accordion'
import React, { useEffect, useState } from 'react'
import { AccordionOverrides, AlertLabel, TypographyStyle } from './DriverEtaAlert.styled'
import { Block } from 'baseui/block'
import { Warning } from '@phosphor-icons/react'
import { primitives } from 'components/homepage/custom-theme'
import { LabelSmall, ParagraphXSmall } from 'baseui/typography'
import { useTranslation } from 'react-i18next'
import { getDriverEtaLabelAlert } from 'components/utils/is-driver-eta-threshold-exceeded'
import { appointmentService } from 'components/services'
import { StatusCodes } from 'components/constants/http-status-codes'
import moment from 'moment-timezone'

const DriverEtaAlert = ({ facilityIds, date }) => {
  const { t, i18n } = useTranslation()
  const [driverEtaAlerts, setdriverEtaAlerts] = useState({})

  useEffect(() => {
    if (facilityIds.length > 0 && date) {
      appointmentService
        .driverEtaAlerts(facilityIds, new Date(date).toISOString())
        .then(([results, status]) => {
          if (status === StatusCodes.OK) {
            setdriverEtaAlerts(results)
          }
        })
        .catch(() => {
          console.error('Something went wrong loading driver ETA alerts')
        })
    }
  }, [date, JSON.stringify(facilityIds)])

  const getAlertLabel = eta => {
    const { identifiers, appointment_type_name, arrival_time, carrier_name, location, driver_eta } =
      eta
    const timeFormated = moment(arrival_time).format('h:mm a')
    return (
      <AlertLabel key={identifiers}>
        {`${identifiers} ${appointment_type_name} ${timeFormated} ${carrier_name} `}
        {getDriverEtaLabelAlert(driver_eta, arrival_time)}
      </AlertLabel>
    )
  }

  return (
    Object.keys(driverEtaAlerts).length > 0 && (
      <Accordion overrides={AccordionOverrides}>
        <Panel
          title={
            <Block alignItems="center" display="flex" gridGap="scale400">
              <Warning color={primitives.warning} size={16} />
              <LabelSmall display="inline" color={primitives.warning} $style={TypographyStyle}>
                {t('Appointments.DriverEta.Alert.Title.Text')}
              </LabelSmall>
            </Block>
          }>
          {Object.keys(driverEtaAlerts).map(facilityName => (
            <Block key={facilityName}>
              <ParagraphXSmall display="inline" $style={TypographyStyle}>
                <strong>{facilityName}</strong>{' '}
                {driverEtaAlerts[facilityName].map(appointment => getAlertLabel(appointment))}
              </ParagraphXSmall>
            </Block>
          ))}
        </Panel>
      </Accordion>
    )
  )
}

export default DriverEtaAlert
