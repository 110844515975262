import { ParagraphXSmall } from 'baseui/typography'
import React, { FC } from 'react'
import { AuditLog } from '..'
import TimeFormatter from 'components/utils/time-formatter'
import { getDriverEtaLabel } from 'components/utils/is-driver-eta-threshold-exceeded'

const fullTimeFormatter = value => new TimeFormatter('fullDate').format(value)

const EtaLogEntry: FC<AuditLog> = props => {
  const { createdAt, user, username, comment, auditedChanges, arrivalTime, ...otherProps } = props
  const [, newTime] = auditedChanges?.driver_eta
  const auditLogsMessages = comment
  const dateLabel = fullTimeFormatter(createdAt)

  const etaLabel = newTime && arrivalTime ? getDriverEtaLabel(newTime, arrivalTime) : ''

  return (
    <>
      <ParagraphXSmall data-testId="eta-logs-entry">
        {dateLabel} {auditLogsMessages} {etaLabel}
      </ParagraphXSmall>
    </>
  )
}

export default EtaLogEntry
